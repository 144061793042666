import React, { useReducer } from 'react'
import CommentContext from './commentContext'
import CommentReducer from './commentReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import { RESPONSE_STATUS, CLEAR_RESPONSE } from './commentTypes'

const CommentState = (props) => {
    const initialState = {
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(CommentReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllComments = async (formData, fromVariable) => {
        try {
            const from = fromVariable ? fromVariable : 'getAllComments'
            const [res] = await Promise.all([
                apiCall('post', 'getAllComments', formData, '', 'comment'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllReplyComments = async (formData, fromVariable) => {
        try {
            const from = fromVariable ? fromVariable : 'getAllReplyComments'
            const [res] = await Promise.all([
                apiCall('post', 'getAllReplyComments', formData, '', 'comment'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const saveComment = async (formData, fromVariable) => {
        try {
            const from = fromVariable ? fromVariable : 'saveComment'
            const [res] = await Promise.all([
                apiCall('post', 'saveComment', formData, '', 'comment'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <CommentContext.Provider
            value={{
                responseStatus: state.responseStatus,
                saveComment,
                getAllComments,
                getAllReplyComments,
                clearResponse,
            }}
        >
            {props.children}
        </CommentContext.Provider>
    )
}

export default CommentState
