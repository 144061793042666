import React, { useReducer } from 'react'
import OfferContext from './offerContext'
import OfferReducer from './offerReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_SEARCH,
    GET_ALL_USER_OFFERS,
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
    GET_SINGLE_CART,
    CLEAR_SINGLE_CART,
} from './offerTypes'

const OfferState = (props) => {
    const initialState = {
        search_allproducts: {
            cartrecords: [],
            noncartrecords: [],
        },
        search_singlecart: {
            cartrecords: [],
            cartvalues: {},
            cartLocation: {},
        },
        search_alluseroffers: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            from: '',
        },

        responseStatus: null,
    }

    const [state, dispatch] = useReducer(OfferReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const addToCart = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addToCart', formData, '', 'makeoffer'),
            ])
            resp.commonResponse(res.data, 'addToCart')
        } catch (err) {
            resp.commonErrorResponse('addToCart')
        }
    }

    const addToMakeOffer = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addToMakeoffer', formData, '', 'makeoffer'),
            ])
            resp.commonResponse(res.data, 'addToMakeoffer')
        } catch (err) {
            resp.commonErrorResponse('addToCart')
        }
    }

    const refreshCart = async (formData, noAlert, from) => {
        from = from ? from : 'refreshCart'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'refreshCart', formData, '', 'makeoffer'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const getSingleCartProducts = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkout', formData, '', 'makeoffer'),
            ])
            const from = 'cartsearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_CART,
                    payload: {
                        cartrecords: res.data.data.responseData.cartItems.length
                            ? res.data.data.responseData.cartItems
                            : [],
                        cartvalues: res.data.data.responseData.cartValues,
                        cartLocation: res.data.data.responseData.cartLocation,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    const getAllCartProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'search', formData, '', 'makeoffer')])
            const from = 'cartsearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_SEARCH,
                    payload: {
                        cartrecords: res.data.data.responseData.cartItems.length
                            ? res.data.data.responseData.cartItems
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res.data.status,
                        message: res.data.data.message,
                        type: res.data.data.responseType,
                        from: from,
                    },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    const getMakeofferAllCartProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'search', formData, '', 'makeoffer')])
            const from = 'makeoffer'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_SEARCH,
                    payload: {
                        cartrecords: res.data.data.responseData.cartItems.length
                            ? res.data.data.responseData.cartItems
                            : [],
                        noncartrecords: res.data.data.responseData.nonCartItems.length
                            ? res.data.data.responseData.nonCartItems
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res.data.status,
                        message: res.data.data.message,
                        type: res.data.data.responseType,
                        from: from,
                    },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            console.log('Something went wrong!', err)
            resp.commonErrorResponse('makeoffer')
        }
    }

    // Register User
    const changeOnCart = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'changeStatus', formData, '', 'makeoffer'),
            ])
            resp.commonResponse(res.data, 'cartchange')
        } catch (err) {
            resp.commonErrorResponse('cartchange')
        }
    }

    const cancelItem = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'cancelItem', formData, '', 'cart')])
            resp.commonResponse(res.data, 'cartchange')
        } catch (err) {
            resp.commonErrorResponse('cartchange')
        }
    }

    const saveAddress = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'saveAddress', formData, '', 'cart')])
            resp.commonResponse(res.data, 'cartaddress')
        } catch (err) {
            resp.commonErrorResponse('cartaddress')
        }
    }

    const getAllUserOffers = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'alloffers', formData, '', 'makeoffer'),
            ])
            const from = 'alloffers'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_USER_OFFERS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    const clearSingleCart = () =>
        dispatch({
            type: CLEAR_SINGLE_CART,
        })

    return (
        <OfferContext.Provider
            value={{
                search_allproducts: state.search_allproducts,
                search_singlecart: state.search_singlecart,
                search_alluseroffers: state.search_alluseroffers,
                responseStatus: state.responseStatus,
                getAllUserOffers,
                addToCart,
                addToMakeOffer,
                refreshCart,
                getSingleCartProducts,
                clearResponse,
                saveAddress,
                getAllCartProducts,
                getMakeofferAllCartProducts,
                changeOnCart,
                cancelItem,
                clearSingleCart,
            }}
        >
            {props.children}
        </OfferContext.Provider>
    )
}

export default OfferState
