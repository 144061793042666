import {
    GET_ALL_SEARCH,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_USER_OFFERS,
    GET_AVAILABLE_APPOINT,
    GET_SINGLE_CART,
    CLEAR_SINGLE_CART,
} from './offerTypes'
export default (state, action) => {
    switch (action.type) {
        case GET_ALL_SEARCH:
            return {
                ...state,
                search_allproducts: action.payload,
            }
        case GET_ALL_USER_OFFERS:
            return {
                ...state,
                search_alluseroffers: action.payload,
            }
        case GET_SINGLE_CART:
            return {
                ...state,
                search_singlecart: action.payload,
            }
        case CLEAR_SINGLE_CART:
            return {
                ...state,
                search_singlecart: {
                    cartrecords: [],
                    cartvalues: {},
                    cartLocation: {},
                },
            }
        case GET_AVAILABLE_APPOINT:
            return {
                ...state,
                available_appointments: action.payload,
            }

        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
